<template>
    <section class="nosotras">
        <v-container>
            <v-row align="start" justify="center">
                <v-col cols="12">
                    <h1
                        id="levels-title"
                        class="
                            text-h3 text-left text-uppercase
                            font-weight-bold
                            text-shadow-red
                            my-6
                        "
                    >
                        Acerca de Nosotras
                    </h1>
                </v-col>
            </v-row>
            <v-row align="center" justify="start">
                <v-col cols="12" sm="8" lg="7">
                    <v-card
                        color="indigo darken-2"
                        rounded="lg"
                        elevation="4"
                        class="pa-4 mb-16"
                        id="card-two"
                    >
                        <v-card-title class="text-xs-h5 text-h4 white--text">
                            Nuestra Misi&oacute;n
                        </v-card-title>
                        <v-card-text class="text-body-1">
                            <p class="white--text">
                                Formar niños íntegros, capaces de desarrollarse
                                armónicamente en todas sus áreas, en un ambiente
                                de confianza, afecto y seguridad.
                            </p>
                            <p class="white--text">
                                Promocionar el buen trato, estilos de vida
                                saludables, trabajo en equipo, compañerismo y
                                retroalimentación.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3" lg="4">
                    <cloud-single :height="height" class="clouds" />
                </v-col>
            </v-row>
            <v-row align="center" justify="end">
                <v-col cols="12" sm="3" lg="4" order="last" order-sm="first">
                    <cloud-single :height="height" class="clouds" />
                </v-col>
                <v-col cols="12" sm="8" lg="7">
                    <v-card
                        color="purple darken-1"
                        rounded="lg"
                        elevation="4"
                        class="pa-4 mb-16"
                        id="card-two"
                    >
                        <v-card-title class="text-xs-h5 text-h4 white--text">
                            Nuestra Visi&oacute;n
                        </v-card-title>
                        <v-card-text class="text-body-1">
                            <p class="white--text">
                                Ser reconocidos por la comunidad como una
                                institución educativa donde los niños y niñas
                                reciben educación oportuna, de calidad en
                                espacios creativos, e innovadores.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center" justify="start">
                <v-col cols="12" sm="8" lg="7">
                    <v-card
                        color="red darken-1"
                        rounded="lg"
                        elevation="4"
                        class="pa-4 mb-16"
                        id="card-two"
                    >
                        <v-card-title class="text-xs-h5 text-h4 white--text">
                            Nuestros Pilares Val&oacute;ricos
                        </v-card-title>
                        <v-card-text class="text-body-1">
                            <p class="white--text">
                                Contribuir a desarrollar en los niños valores y
                                hábitos universales tales como: Solidaridad,
                                Respeto, Amistad, Lealtad, y Responsabilidad.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="3" lg="4">
                    <cloud-single :height="height" class="clouds" />
                </v-col>
            </v-row>
            <v-row align="center" justify="end">
                <v-col cols="12" sm="3" lg="4" order="last" order-sm="first">
                    <cloud-single :height="height" class="clouds" />
                </v-col>
                <v-col cols="12" sm="8" lg="7">
                    <v-card
                        color="deep-orange darken-1"
                        rounded="lg"
                        elevation="4"
                        class="pa-4 mb-16"
                        id="card-two"
                    >
                        <v-card-title class="text-xs-h5 text-h4 white--text">
                            Nuestro Curriculum
                        </v-card-title>
                        <v-card-text class="text-body-1">
                            <p class="white--text">
                                Es integral, que aspira a que el niño y niña se
                                desarrolle en todas sus áreas, potenciando al
                                máximo sus conocimiento, intereses, destrezas y
                                habilidades e incentivando valores universales
                                tales como amistad, compañerismo, empatía,
                                autoestima y solidaridad.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12"></v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import CloudSingle from '../components/svg/CloudSingle.vue';
export default {
    data() {
        return {
            height: 150,
        };
    },
    components: {
        CloudSingle,
    },
};
</script>

<style lang="scss" scoped>
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

.text-shadow-red {
    text-shadow: 0px 4px 3px $official-red;
}
.text-shadow-orange {
    text-shadow: 0px 4px 3px $official-orange;
}
.text-shadow-yellow {
    text-shadow: 0px 4px 3px $official-yellow;
}

.clouds {
    animation-name: clouds;
    animation-duration: 10s;
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
}
@keyframes clouds {
    from {
        transform: translateX(-100px);
    }
    to {
        transform: translateX(200px);
    }
}

.nosotras {
    margin-bottom: 250px;
}
</style>
